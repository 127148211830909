export const privateRoutePath = {
  base: "/",
  home: "/home",

  child1: `/home/:child1`,
  test: "/test",
  testP: "/test/:param/:param2/:param3",
  playground: "/playground",
  master: "/master",
  worklog: "/master/worklog",
  designation: "/master/designation",
  stockCategory: "/master/stock-category",
  stockForm: "/master/stock-form",
  departmentSetup: "/master/department-setup",
  settings: "/settings",
  calendar: "/calendar",
  map: "/map",
  document: "/master/document/management",
  country: "/master/location/country",
  fiscalyear: "/master/fiscalyear",
  //couponpro
  dashboard: "/dashboard",
  menuManagement: "/menu-management",
  carosoul: "/carosoul",
  dragDrop: "/drag-drop",
  userManagement: "/user-management",
  branchManagement: "/branch-management",
  vendorOnboarding: "/vendor-onboarding",
  currentVendors: "/vendor-onboarding/current",
  newVendorRequest: "/vendor-onboarding/new",
  rejectedVendors: "/vendor-onboarding/rejected",
  couponDiscountManagement: "/coupon-discount-mgmt",
  activeCoupons: "/coupon-discount-mgmt/active",
  draftCoupons: "/coupon-discount-mgmt/draft",
  expiredCoupons: "/coupon-discount-mgmt/discount",
  businessCategory: "/buisness-category",
  moduleMgmt: "/module-mgmt",
  qrMgmt: "/qr-mgmt",
  clientFeedback: "/client-feedback",
  activeQR: "/qr-mgmt/active",
  expiredQR: "/qr-mgmt/expired",

  contentMgmt: "/content-mgmt",
  contentMgmtHome: "/content-mgmt/home",
  contentMgmtHomeDraft: "/content-mgmt/home/draft",
  contentMgmtHomePublished: "/content-mgmt/home/published",
  contentMgmtAboutUs: "/content-mgmt/about-us",
  contentMgmtContact: "/content-mgmt/contact",
  contentMgmtBlog: "/content-mgmt/blog",
  contentMgmtHowItWorks: "/content-mgmt/how-it-works",
  contentMgmtFaq: "/content-mgmt/faq",
  contentMgmtFaqCategory: "/content-mgmt/faq/category",
  contentMgmtFaqQueries: "/content-mgmt/faq/queries",
  contentMgmtAdvertisement: "/content-mgmt/advertisement",
  contentMgmtAdMgmt: "/content-mgmt/ad-mgmt",
  contentMgmtBanner: "/content-mgmt/banner",
  contentMgmtPrivacyPolicy: "/content-mgmt/privacy-policy",

  gamificationAndRewards: "/gamification-and-rewards",
  gamificationAndRewardsPrivilege: "/gamification-and-rewards/privilege",
  gamificationAndRewardsGoal: "/gamification-and-rewards/goal",
  gamificationAndRewardsReward: "/gamification-and-rewards/reward",
  gamificationAndRewardsBadge: "/gamification-and-rewards/badge",
  gamificationAndRewardsMissions: "/gamification-and-rewards/missions",

  designTemplateMgmt: "/design-template-mgmt",
  designTemplateMgmtCouponStudio: "/design-template-mgmt/coupon-studio",

  moduleSubscription: "/module-subscription",

  discountSetup: "/discount-setup",

  merchantSetup: "/merchant-setup",
  freemium: "/freemium",
  newsletter: "/news-letter",
  sendEmail:"/send-email",
  sendEmailCreate:"/send-email/create",
  sendEmailView:"/send-email/view/:id",
  sendEmailEdit:"/send-email/edit/:id",
} as const;
