import { privateRoutePath } from './private/private-route.path';
import { publicRoutePath } from './public/public-route.path';

export enum ROUTEAUTHTYPEENUM {
  AUTHORIZED = 'authorized',
  UNAUTHORIZED = 'unauthorized',
}

const routePaths = {
  ...publicRoutePath,
  ...privateRoutePath,
} as const;

export { publicRoutePath, privateRoutePath, routePaths };
