import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import toastConfig from '../config/toast.config'
import queryClient from '../lib/react-query'

interface IAppProvider {
  children: React.ReactNode
}

function AppProvider({ children }: IAppProvider) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ToastContainer {...toastConfig} />
    </QueryClientProvider>
  )
}

export default AppProvider
