import React, { useContext } from 'react'

interface IFullPageContext {
  isFullPageOpen: boolean
  setIsFullPageOpen: (isOpen?: boolean) => void
}

export const FullPageContext = React.createContext<IFullPageContext>({
  isFullPageOpen: false,
  setIsFullPageOpen: () => {},
})

interface Props {
  children: React.ReactNode
}
export const FullPageProvider = ({ children }: Props) => {
  const [isFullPageOpen, setIsFullPageOpen] = React.useState(false)

  const contextValue = React.useMemo(
    () => ({
      isFullPageOpen,
      setIsFullPageOpen: (state?: boolean) => setIsFullPageOpen(state || !isFullPageOpen),
    }),
    [isFullPageOpen]
  )

  return (
    <FullPageContext.Provider value={contextValue}>
      {children}
    </FullPageContext.Provider>
  )
}
export const useFullPage = () => {
  return useContext(FullPageContext)
}