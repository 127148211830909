import React from 'react';
import FallbackLoaderImg from '../../designAssets/img/FallbackLoader.png';
import { Box, Image } from '../designCore/default';

function FallbackLoader() {
  return (
    <Box
      className='d-flex justify-content-center align-items-center w-100'
      style={{ height: '100%' }}
    >
      <Box style={{ height: '100px' }}>
        <Image
          src={FallbackLoaderImg}
          className='w-100 h-100 object-fit-cover'
        />
      </Box>
    </Box>
  );
}

export default FallbackLoader;
