export const publicRoutePath = {
  base: "/",
  login: "/vendor/login",
  signUp: "/auth/register",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  aboutUs: "/about-us",
  blog: "/blog",
  homePage: "/home-page",
  contact: "/contact",
  faq: "/faq",
  blogs: "/blogs",
  blogsDetails: "/blogs/:blogId",
  about: "/about",
  join: "/join",
  profile: "/profile",
  stores: "/stores",
  storesDetails: "/stores-details",
  businessCategoryDetails: "/business-categories",
  couponDetails: "/coupon-details/:id",
  howItWorks: "/how-it-works",
  test: "/test/:param/:param2/:param3",
  privacyPolicy: "/privacy-policy",
  publicUserLogin: "/auth/login",
  bestDiscounts:"/best-discount",
  topVendor:"/top-vendor",
  topVendorDetail:"/top-vendor/:vendorId",
} as const;
