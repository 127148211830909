import React from "react";
import {_RouteObject} from "react-router-dom";

import {createRoute} from "../create-route";
import {privateRoutePath} from "./private-route.path";

const NotFound = React.lazy(() => import("../../../component/NotFound"));
const ClientFeedback = React.lazy(
  () => import("../../../core/private/ClientFeedback/ClientFeedback")
);
const NewsLetterSubscription = React.lazy(
  () => import("../../../core/private/NewsLetterSubscription")
);
const Freemium = React.lazy(() => import("../../../core/private/Freemuim"));

const Root = React.lazy(() => import("../../../core/private/Root"));
const ModuleSubscription = React.lazy(
  () => import("../../../core/private/ModuleSubscription")
);
const MenuManagement = React.lazy(
  () => import("../../../core/private/MenuManagement")
);

const VendorOnboarding = React.lazy(
  () => import("../../../core/private/VendorOnboarding")
);
const CurrentVendors = React.lazy(
  () => import("../../../core/private/VendorOnboarding/CurrentVendors")
);
const NewVendorRequests = React.lazy(
  () => import("../../../core/private/VendorOnboarding/NewVendorRequest")
);
const RejectedVendors = React.lazy(
  () => import("../../../core/private/VendorOnboarding/RejectedVendors")
);

const CouponDiscountManagement = React.lazy(
  () => import("../../../core/private/CouponDiscountManagement")
);
const ActiveCoupons = React.lazy(
  () => import("../../../core/private/CouponDiscountManagement/ActiveCoupons")
);
const DraftCoupons = React.lazy(
  () => import("../../../core/private/CouponDiscountManagement/DraftCoupons")
);
const ExpiredCoupons = React.lazy(
  () => import("../../../core/private/CouponDiscountManagement/ExpiredCoupons")
);

const Dashboard = React.lazy(() => import("../../../core/private/Dashboard"));
const CustomCarosoul = React.lazy(
  () => import("../../../component/CustomCarosoul")
);
const CouponCard = React.lazy(() => import("../../../core/private/CouponCard"));
const UserManagement = React.lazy(
  () => import("../../../core/private/UserManagement")
);
const BranchManagement = React.lazy(
  () => import("../../../core/private/BranchManagement")
);
const BusinessCategoryManagement = React.lazy(
  () => import("../../../core/private/BusinessCategoryManagement")
);
const ModuleManagement = React.lazy(
  () => import("../../../core/private/ModuleManagement")
);

const QRManagement = React.lazy(
  () => import("../../../core/private/QRManagement")
);
const ActiveQR = React.lazy(
  () => import("../../../core/private/QRManagement/ActiveQR")
);
const ExpiredQR = React.lazy(
  () => import("../../../core/private/QRManagement/ExpiredQR")
);

const ContentManagement = React.lazy(
  () => import("../../../core/private/ContentManagement")
);
const AboutUs = React.lazy(
  () => import("../../../core/private/ContentManagement/AboutUs")
);
const ManageAds = React.lazy(
  () => import("../../../core/private/ContentManagement/ManageAds")
);
const Banner = React.lazy(
  () => import("../../../core/private/ContentManagement/Banner")
);
const Blog = React.lazy(
  () => import("../../../core/private/ContentManagement/Blog")
);
const HIW = React.lazy(
  () => import("../../../core/private/ContentManagement/HowItWorks")
);
const Contact = React.lazy(
  () => import("../../../core/private/ContentManagement/Contact")
);
const Faq = React.lazy(
  () => import("../../../core/private/ContentManagement/Faq")
);
const Category = React.lazy(
  () => import("../../../core/private/ContentManagement/Faq/Category")
);
const Queries = React.lazy(
  () => import("../../../core/private/ContentManagement/Faq/Queries")
);
const PrivacyPolicy = React.lazy(
  () => import("../../../core/private/ContentManagement/PrivacyPolicy")
);
const HomePage = React.lazy(
  () => import("../../../core/private/ContentManagement/HomePage")
);
const DraftContent = React.lazy(
  () => import("../../../core/private/ContentManagement/HomePage/DraftContent")
);
const PublishedContent = React.lazy(
  () =>
    import("../../../core/private/ContentManagement/HomePage/PublishedContent")
);

const GamificationAndRewards = React.lazy(
  () => import("../../../core/private/GamificationAndRewards")
);
const BadgeManagement = React.lazy(
  () => import("../../../core/private/GamificationAndRewards/BadgeManagement")
);
const GoalManagement = React.lazy(
  () => import("../../../core/private/GamificationAndRewards/GoalManagement")
);
const MissionsManagement = React.lazy(
  () =>
    import("../../../core/private/GamificationAndRewards/MissionsManagement")
);
const PrivilegeManagement = React.lazy(
  () =>
    import("../../../core/private/GamificationAndRewards/PrivilegeManagement")
);
const Rewardmanagement = React.lazy(
  () => import("../../../core/private/GamificationAndRewards/Rewardmanagement")
);

const DesignTemplateManagement = React.lazy(
  () => import("../../../core/private/DesignTemplateManagement")
);

const CouponDesignStudio = React.lazy(
  () =>
    import("../../../core/private/DesignTemplateManagement/CouponDesignStudio")
);
// const ViewDesignTemplateList = React.lazy(
//   () =>
//     import(
//       "core/private/DesignTemplateManagement/ViewDesignTemplateList/ViewDesignTemplateList"
//     )
// );
const DiscountSetup = React.lazy(
  () => import("core/private/MasterSetup/DiscountSetup")
);
const Merchant = React.lazy(() => import("core/private/MerchantSetup/index"));
const SendEmail = React.lazy(() => import("core/private/SendEmail/index"));
const SendEmailForm = React.lazy(() => import("core/private/SendEmail/SendEmailForm"));

export const privateRoutes: _RouteObject<"private">[] = [
  createRoute({
    path: privateRoutePath.base,
    element: Root,
    children: [
      // createRoute({
      //   path: privateRoutePath.home,
      //   element: Home,
      //   children: [
      //     createRoute({
      //       path: privateRoutePath.child1,
      //       element: Child1,
      //     }),
      //   ],
      // }),
      createRoute({
        path: "/menu-management",
        element: MenuManagement,
      }),
      createRoute({
        path: privateRoutePath.newsletter,
        element: NewsLetterSubscription,
      }),
      createRoute({
        path: privateRoutePath.clientFeedback,
        element: ClientFeedback,
      }),
      createRoute({
        path: privateRoutePath.businessCategory,
        element: BusinessCategoryManagement,
      }),
      createRoute({
        path: privateRoutePath.qrMgmt,
        element: QRManagement,
        children: [
          createRoute({
            path: privateRoutePath.activeQR,
            element: ActiveQR,
          }),
          createRoute({
            path: privateRoutePath.expiredQR,
            element: ExpiredQR,
          }),
        ],
      }),
      createRoute({
        path: privateRoutePath.moduleMgmt,
        element: ModuleManagement,
      }),
      createRoute({
        path: privateRoutePath.vendorOnboarding,
        element: VendorOnboarding,
        children: [
          createRoute({
            path: privateRoutePath.currentVendors,
            element: CurrentVendors,
          }),
          createRoute({
            path: privateRoutePath.newVendorRequest,
            element: NewVendorRequests,
          }),
          createRoute({
            path: privateRoutePath.rejectedVendors,
            element: RejectedVendors,
          }),
        ],
      }),
      createRoute({
        path: privateRoutePath.couponDiscountManagement,
        element: CouponDiscountManagement,
        children: [
          createRoute({
            path: privateRoutePath.activeCoupons,
            element: ActiveCoupons,
          }),
          createRoute({
            path: privateRoutePath.draftCoupons,
            element: DraftCoupons,
          }),
          createRoute({
            path: privateRoutePath.expiredCoupons,
            element: ExpiredCoupons,
          }),
        ],
      }),
      createRoute({
        path: privateRoutePath?.dashboard,
        element: Dashboard,
      }),
      createRoute({
        path: privateRoutePath?.freemium,
        element: Freemium,
      }),
      createRoute({
        path: privateRoutePath?.carosoul,
        element: CustomCarosoul,
      }),
      createRoute({
        path: privateRoutePath?.dragDrop,
        element: CouponCard,
      }),
      createRoute({
        path: privateRoutePath?.userManagement,
        element: UserManagement,
      }),
      createRoute({
        path: privateRoutePath?.branchManagement,
        element: BranchManagement,
      }),
      createRoute({
        path: privateRoutePath?.contentMgmt,
        element: ContentManagement,
        children: [
          createRoute({
            path: privateRoutePath.contentMgmtHome,
            element: HomePage,
            children: [
              createRoute({
                path: privateRoutePath.contentMgmtHomeDraft,
                element: DraftContent,
              }),
              createRoute({
                path: privateRoutePath.contentMgmtHomePublished,
                element: PublishedContent,
              }),
            ],
          }),
          createRoute({
            path: privateRoutePath.contentMgmtAboutUs,
            element: AboutUs,
          }),
          createRoute({
            path: privateRoutePath.contentMgmtAdvertisement,
            element: ManageAds,
          }),
          createRoute({
            path: privateRoutePath.contentMgmtAdMgmt,
            element: Banner,
          }),
          createRoute({
            path: privateRoutePath.contentMgmtBanner,
            element: Banner,
          }),
          createRoute({
            path: privateRoutePath.contentMgmtContact,
            element: Contact,
          }),
          createRoute({
            path: privateRoutePath.contentMgmtBlog,
            element: Blog,
          }),
          createRoute({
            path: privateRoutePath.contentMgmtHowItWorks,
            element: HIW,
          }),
          createRoute({
            path: privateRoutePath.contentMgmtPrivacyPolicy,
            element: PrivacyPolicy,
          }),
          createRoute({
            path: privateRoutePath.contentMgmtFaq,
            element: Faq,
            children: [
              createRoute({
                path: privateRoutePath.contentMgmtFaqCategory,
                element: Category,
              }),
              createRoute({
                path: privateRoutePath.contentMgmtFaqQueries,
                element: Queries,
              }),
            ],
          }),
        ],
      }),
      createRoute({
        path: privateRoutePath.gamificationAndRewards,
        element: GamificationAndRewards,
        children: [
          createRoute({
            path: privateRoutePath.gamificationAndRewardsPrivilege,
            element: PrivilegeManagement,
          }),
          createRoute({
            path: privateRoutePath.gamificationAndRewardsGoal,
            element: GoalManagement,
          }),
          createRoute({
            path: privateRoutePath.gamificationAndRewardsReward,
            element: Rewardmanagement,
          }),
          createRoute({
            path: privateRoutePath.gamificationAndRewardsBadge,
            element: BadgeManagement,
          }),
          createRoute({
            path: privateRoutePath.gamificationAndRewardsMissions,
            element: MissionsManagement,
          }),
        ],
      }),
      createRoute({
        path: privateRoutePath.designTemplateMgmt,
        element: DesignTemplateManagement,
        children: [
          // createRoute({
          //   path: privateRoutePath.designTemplateMgmt,
          //   element: ViewDesignTemplateList,
          // }),
          createRoute({
            path: privateRoutePath.designTemplateMgmtCouponStudio,
            element: CouponDesignStudio,
          }),
        ],
      }),
      createRoute({
        path: privateRoutePath.moduleSubscription,
        element: ModuleSubscription,
      }),
      createRoute({
        path: privateRoutePath.discountSetup,
        element: DiscountSetup,
      }),
      createRoute({
        path: privateRoutePath.merchantSetup,
        element: Merchant,
      }),
      createRoute({
        path: privateRoutePath.sendEmail,
        element: SendEmail
      }),
      createRoute({
        path: privateRoutePath.sendEmailCreate,
        element: SendEmailForm,
      }),
    ],
  }),
  createRoute({
    path: "*",
    element: NotFound,
  }),
];
