import React from 'react';

interface Props {
  component?:
    | 'div'
    | 'span'
    | 'aside'
    | 'main'
    | 'header'
    | 'section'
    | 'footer'
    | 'ul'
    | 'li';
  children?: React.ReactNode;
  className?: string;
  ariaLabel?: string;
  style?: React.CSSProperties;
  ref?: React.LegacyRef<any>;
  onClick?: any;
  onDrop?: any;
  onDragOver?: any;
  id?: string;
}

const Box = ({
  children,
  className,
  component: Component = 'div',
  style = {},
  ref,
  onClick,
  onDrop,
  onDragOver,
  id,
}: Props): React.ReactElement => {
  return (
    <Component
      className={className}
      style={style}
      ref={ref}
      onClick={onClick}
      onDrop={onDrop}
      onDragOver={onDragOver}
      id={id}
    >
      {children}
    </Component>
  );
};

export default Box;
