import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import "./designAssets/scss/core.scss";
import { useAuth } from "./providers";
import { privateRoutes } from "./router/routes/private/private.routes";
import {
  publicRoutes,
  unAuthorizedRoutes,
} from "./router/routes/public/public.routes";
import { SidebarProvider } from "./providers/SidebarProvider";
import { FullPageProvider } from "./providers/FullPageProvider";
import { USERTYPEENUM } from "utility/enums/user-type-enum";
import { useMemo } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

function App() {
  const { isAuthenticated, authenticatedUserType } = useAuth();

  const isInternalUser = useMemo(
    () =>
      authenticatedUserType &&
      [
        USERTYPEENUM.INTERNAL_USER,
        USERTYPEENUM.SUPER_ADMIN,
        USERTYPEENUM.VENDOR_USER,
        USERTYPEENUM.VENDOR_ADMIN,
      ].includes(authenticatedUserType),
    [authenticatedUserType]
  );

  const router = createBrowserRouter(
    isAuthenticated && authenticatedUserType && isInternalUser
      ? privateRoutes
      : isAuthenticated &&
        authenticatedUserType === USERTYPEENUM.GENERAL_PUBLIC_USER
      ? publicRoutes
      : [...unAuthorizedRoutes, ...publicRoutes]
  );
  return isInternalUser ? (
    <FullPageProvider>
      <SidebarProvider>
        <RouterProvider router={router} />
      </SidebarProvider>
    </FullPageProvider>
  ) : (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ""}
    >
      <RouterProvider router={router} />
    </GoogleReCaptchaProvider>
  );
}

export default App;
