import React from "react";
import { _RouteObject } from "react-router-dom";
import { ROUTEAUTHTYPEENUM } from "..";

import { createRoute } from "../create-route";
import { publicRoutePath } from "./public-route.path";

const Login = React.lazy(() => import("core/public/Login"));
const PublicUserLogin = React.lazy(
  () => import("core/public/PublicUserLogin/PublicUserLogin")
);
const SignUp = React.lazy(() => import("core/public/SignUp"));
const ForgotPassword = React.lazy(() => import("core/public/ForgotPassword"));
const ResetPassword = React.lazy(() => import("core/public/ResetPassword"));
const NotFound = React.lazy(() => import("component/NotFound"));
const HomePage = React.lazy(() => import("core/public/HomePage"));

// website
const Website = React.lazy(() => import("core/public/Website"));
const Home = React.lazy(() => import("core/public/Website/Home"));
const ContactWeb = React.lazy(() => import("core/public/Website/Contact"));
const FAQ = React.lazy(() => import("core/public/Website/FAQ"));
const Blogs = React.lazy(() => import("core/public/Website/Blogs"));
const BlogsDetails = React.lazy(
  () => import("core/public/Website/Blogs/BlogsDetails")
);
const About = React.lazy(() => import("core/public/Website/AboutUs"));
const Profile = React.lazy(() => import("core/public/Website/Profile"));
const Stores = React.lazy(() => import("core/public/Website/Stores"));
const StoreDetails = React.lazy(
  () => import("core/public/Website/Stores/StoreDetails")
);
const BusinessCategoryDetails = React.lazy(
  () => import("core/public/Website/Business-Categories/Details")
);
const CouponDetails = React.lazy(
  () => import("core/public/Website/CouponDetails")
);
const JoinAsVendor = React.lazy(
  () => import("core/public/Website/JoinAsVendor")
);
const HowItWorks = React.lazy(() => import("core/public/Website/HowItWorks"));
const PrivacyPolicy = React.lazy(
  () => import("core/public/Website/PrivacyPolicy")
);
const BestDiscounts = React.lazy(
  () => import("core/public/Website/BestDiscounts")
);
const TopVendor = React.lazy(
  () => import("core/public/Website/TopVendors")
);
const TopVendorDetails = React.lazy(
  () => import("core/public/Website/TopVendors/TopVendorDetails")
);

export const unAuthorizedRoutes: _RouteObject<"public">[] = [
  createRoute({
    path: publicRoutePath.login,
    element: Login,
    routeAuthType: [ROUTEAUTHTYPEENUM.UNAUTHORIZED],
  }),
  createRoute({
    path: publicRoutePath.publicUserLogin,
    element: PublicUserLogin,
    routeAuthType: [ROUTEAUTHTYPEENUM.UNAUTHORIZED],
  }),
  createRoute({
    path: publicRoutePath.signUp,
    element: SignUp,
    routeAuthType: [ROUTEAUTHTYPEENUM.UNAUTHORIZED],
  }),
  createRoute({
    path: publicRoutePath.forgotPassword,
    element: ForgotPassword,
    routeAuthType: [ROUTEAUTHTYPEENUM.UNAUTHORIZED],
  }),
  createRoute({
    path: publicRoutePath.resetPassword,
    element: ResetPassword,
    routeAuthType: [ROUTEAUTHTYPEENUM.UNAUTHORIZED],
  }),
];

export const publicRoutes: _RouteObject<"public">[] = [
  createRoute({
    path: "/home-page",
    element: HomePage,
  }),

  // for website
  createRoute({
    path: publicRoutePath.base,
    element: Website,
    children: [
      createRoute({
        path: publicRoutePath.base,
        element: Home,
      }),
      createRoute({
        path: publicRoutePath.contact,
        element: ContactWeb,
      }),
      createRoute({
        path: publicRoutePath.faq,
        element: FAQ,
      }),
      createRoute({
        path: publicRoutePath.blogs,
        element: Blogs,
      }),
      createRoute({
        path: publicRoutePath.blogsDetails,
        element: BlogsDetails,
      }),
      createRoute({
        path: publicRoutePath.about,
        element: About,
      }),
      createRoute({
        path: publicRoutePath.profile,
        element: Profile,
      }),
      createRoute({
        path: publicRoutePath.stores,
        element: Stores,
      }),
      createRoute({
        path: publicRoutePath.storesDetails,
        element: StoreDetails,
      }),
      createRoute({
        path: publicRoutePath.businessCategoryDetails,
        element: BusinessCategoryDetails,
      }),
      createRoute({
        path: publicRoutePath.couponDetails,
        element: CouponDetails,
      }),
      createRoute({
        path: publicRoutePath.join,
        element: JoinAsVendor,
      }),
      createRoute({
        path: publicRoutePath.howItWorks,
        element: HowItWorks,
      }),
      createRoute({
        path: publicRoutePath.privacyPolicy,
        element: PrivacyPolicy,
      }),
      createRoute({
        path: publicRoutePath.bestDiscounts,
        element: BestDiscounts,
      }),
      createRoute({
        path: publicRoutePath.topVendor,
        element: TopVendor,
      }),
      createRoute({
        path: publicRoutePath.topVendorDetail,
        element: TopVendorDetails,
      }),
    ],
  }),

  createRoute({
    path: "*",
    element: NotFound,
  }),
];
