import React, { FC, useContext, useEffect, useMemo } from 'react';
import TokenService from 'service/token/token.service';
import { USERTYPEENUM } from 'utility/enums/user-type-enum';
import { parseJwt } from 'utility/parse-jwt/parse-jwt';

type IUserInfo = any;

const AuthContext = React.createContext<AuthProps>({
  userData: null,
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  setUserData: () => {},
  authenticatedUserType: null,
  setAuthenticatedUserType: () => {},
});

interface AuthProps {
  userData: any | null;
  isAuthenticated: boolean;
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  setUserData: React.Dispatch<React.SetStateAction<IUserInfo | null>>;
  authenticatedUserType: USERTYPEENUM | null;
  setAuthenticatedUserType: React.Dispatch<
    React.SetStateAction<USERTYPEENUM | null>
  >;
}

const AuthProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = React.useState<IUserInfo | null>(null);
  const userToken = useMemo(() => TokenService.getAccessToken(), []);
  const [isAuthenticated, setIsAuthenticated] = React.useState(!!userToken);
  const [authenticatedUserType, setAuthenticatedUserType] =
    React.useState<USERTYPEENUM | null>(
      userToken ? parseJwt(userToken)?.userType || null : null
    );

  const providerValue = useMemo(
    () => ({
      userData,
      isAuthenticated,
      setIsAuthenticated,
      setUserData,
      setAuthenticatedUserType,
      authenticatedUserType,
    }),
    [userData, isAuthenticated, authenticatedUserType]
  );

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;
