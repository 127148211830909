import TokenService from 'service/token/token.service';
import { USERTYPEENUM } from 'utility/enums/user-type-enum';

interface JWTParsedType {
  aud: string[];
  user_name: string;
  scope: string[];
  userType: USERTYPEENUM;
  exp: number;
  userId: number;
  jti: string;
  client_id: string;
  isPasswordChanged: boolean;
}

export function parseJwt(
  token = TokenService.getAccessToken()
): JWTParsedType | null {
  try {
    if (token) {
      const base64Url: string = token.split('.')[1];
      const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload: string = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      return JSON.parse(jsonPayload);
    }
    return null;
  } catch (error) {
    return null;
  }
}
